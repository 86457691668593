import React from 'react';
import PricingSection from 'containers/SyncStage/Pricing';
import Layout from '../components/layout';

const Pricing = () => {
  return (
    <Layout>
      <PricingSection />
    </Layout>
  );
};
export default Pricing;
